// application-pipes.module.ts
// other imports
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { UploadModule } from '@progress/kendo-angular-upload';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { ImageFallbackDirective } from 'src/app/_directives/image-fallback.directive';
import { BooleanToTextPipe, BytePipe, CamelCaseToSpacesPipe, DurationCleanPipe, DurationPipe, EncodeUrlPipe, EnumEqualPipeTransform, EnumValuePipeTransform, InvertColorPipe, CEUDisplayPipe, CEUCountPipe, LeftPadFilter, NoDataDisplayPipe, PhonePipe, PluralizerPipe, SafeHtmlPipe, SafeStylePipe, StripHtmlPipe, TimezonePipe, TitleCasePipe, TranslateXPipe, TruncatePipe, UnicodePipe, WebsiteUrlPipe, TimeAgoPipe, DisableControlDirective, SecondsToTimePipe } from 'src/app/_helpers/extensions.module';
import { PageLoadHeaderComponent } from 'src/app/_helpers/pageLoadHeader.component';
import { CourseItemComponent } from '../course-item/course-item.component';
import { EventItemComponent } from '../event-item/event-item.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { FilterDropdownControl } from 'src/app/_controls/filter-control';
import { EventDetailsComponent } from 'src/app/events/details/event-details.component';
import { ViewInstructorComponent } from 'src/app/instructors/view-instructor.component';
import { StarRatingControl } from 'src/app/_controls/star-rating';
import { WriteReviewModalComponent } from 'src/app/courses/write-review/write-review-modal.component';
import { StateDropdownControl } from 'src/app/_controls/stateDropdown';
import { EditorHTMLDirective } from 'src/app/_directives/innerHTML.directive';
import { FileDropDirective } from 'src/app/_directives/filedrop.directive';
import { AzureFileUploadControl } from 'src/app/_controls/image-uploader';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IconPickerComponent } from 'src/app/_controls/icon-picker.component';
import { A11yModule } from '@angular/cdk/a11y';
import { TrimDirective } from 'src/app/_directives/trim.directive';
import { MyAccountContainerComponent } from 'src/app/my-account/my-account-container.component';
import { ConferenceItemComponent } from '../conference-item/conference-item.component';
import { CeuDisplayComponent } from '../ceu-display.component';
import { BannerAdDisplayComponent } from "../banner-ad-display.component";
import { PlacesAutoCompleteControl } from "../../_controls/google-autocomplete";
import { JobTrackerComponent } from "../job-tracker.component";

@NgModule({
  imports: [
    UploadModule,
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    RouterModule,
    FormsModule,
    A11yModule,

    ReactiveFormsModule,
    Angular2PromiseButtonModule.forRoot(),
    InlineSVGModule.forRoot(),
    NgCircleProgressModule.forRoot(),
    // dep modules
  ],
  declarations: [BannerAdDisplayComponent,JobTrackerComponent, SecondsToTimePipe, DisableControlDirective, WebsiteUrlPipe,TimeAgoPipe, PlacesAutoCompleteControl, SafeHtmlPipe, PhonePipe, TrimDirective, CEUDisplayPipe, CEUCountPipe, ConferenceItemComponent, UnicodePipe, EncodeUrlPipe, FileDropDirective, WebsiteUrlPipe, EnumValuePipeTransform, TitleCasePipe, DurationCleanPipe, DurationPipe, BytePipe, TruncatePipe, TranslateXPipe, StripHtmlPipe, SafeStylePipe, PluralizerPipe, EnumEqualPipeTransform, CamelCaseToSpacesPipe, BooleanToTextPipe, NoDataDisplayPipe, TimezonePipe, LeftPadFilter, InvertColorPipe, PageLoadHeaderComponent, CourseItemComponent, CeuDisplayComponent, EventItemComponent, ImageFallbackDirective, FilterDropdownControl, ViewInstructorComponent, EventDetailsComponent, StarRatingControl, EditorHTMLDirective, WriteReviewModalComponent, StateDropdownControl, IconPickerComponent, AzureFileUploadControl],
  entryComponents: [WriteReviewModalComponent],
  providers: [EnumValuePipeTransform],
  exports: [BannerAdDisplayComponent,JobTrackerComponent, WebsiteUrlPipe, SecondsToTimePipe, DisableControlDirective, TimeAgoPipe, PlacesAutoCompleteControl, InlineSVGModule, PhonePipe, TranslateModule, CEUDisplayPipe, CEUCountPipe, ConferenceItemComponent, TrimDirective, A11yModule, StateDropdownControl, UnicodePipe, StarRatingControl, UploadModule, Angular2PromiseButtonModule, FormsModule, CommonModule, ReactiveFormsModule, InvertColorPipe, TitleCasePipe, DurationCleanPipe, EnumEqualPipeTransform, DurationPipe, BytePipe, SafeStylePipe, EnumValuePipeTransform, TruncatePipe, WebsiteUrlPipe, SafeHtmlPipe, TimezonePipe, PluralizerPipe, EncodeUrlPipe, CeuDisplayComponent, StripHtmlPipe, CamelCaseToSpacesPipe, TranslateXPipe, BooleanToTextPipe, NoDataDisplayPipe, LeftPadFilter, FileDropDirective, PageLoadHeaderComponent, CourseItemComponent, EventItemComponent, ImageFallbackDirective, IconPickerComponent, FilterDropdownControl, EditorHTMLDirective, ViewInstructorComponent, EventDetailsComponent, AzureFileUploadControl, NgCircleProgressModule],
})
export class ApplicationPipesModule {
  static forRoot(): ModuleWithProviders<ApplicationPipesModule> {
    return {
      ngModule: ApplicationPipesModule,
    };
  }
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
