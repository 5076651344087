import { Injectable } from '@angular/core';
import Enumerable from 'linq';
import { ToastrService } from 'ngx-toastr';
import { totalmem } from 'os';
import { SponsorshipCartModel, SponsorshipProductAddOnViewModel, SponsorshipProductViewModel } from '../_models/generatedModels';
import { CallForSponsorsService } from './generatedServices';

@Injectable()
export class SponsorshipCartService {
  //public RootPageVC: ViewContainerRef | undefined;

  items: SponsorshipCartModel[] = [];

  constructor(public toastr: ToastrService, private callForSponsorsService: CallForSponsorsService) {
    this.deserializeCart();
  }

  get cartTotal(): number {
    var total = Enumerable.from(this.items).sum((x) => x.product.price * x.qty);
    return total;
  }

  get totalItems(): number {
    var total = Enumerable.from(this.items).sum((x) => x.qty * 1);
    return total;
  }

  clear() {
    this.items = [];
    this.serializeCart();
  }

  clearByProduct(product: SponsorshipProductViewModel) {
    this.items = Enumerable.from(this.items).where(x=>x.product != product).toArray();
  }

  addItem(product: SponsorshipProductViewModel, variationId: number, variationName: string, qty: number, selectedAddOns: SponsorshipProductAddOnViewModel[]) {
    if (product.remainingQuantity - qty < 0) {
      return;
    }

    var index = this.items.findIndex((x) => x.product.id == product.id);
    console.log('index' + index);

    if (!product.isBooth && index > -1) {
      this.items[index].qty++;
      if (this.items[index].qty > product.remainingQuantity) {
        this.items[index].qty = product.remainingQuantity;
      }
    } else {
      var item = new SponsorshipCartModel();
      item.product = product;
      item.qty = qty;
      item.variationId = variationId;
      item.variationName = variationName;
      item.selectedAddons = selectedAddOns;
      item.qty = qty;

      // if (!this.items.find((x) => x.variationId == variationId && x.product.id == product.id))
      this.items.push(item);
    }
    this.serializeCart();
  }

  clearBooths() {
    this.items = this.items.filter((x) => !x.product.isBooth);
  }

  remove(index: number) {
    this.items.splice(index, 1);
    this.serializeCart();
  }

  serializeCart() {
    localStorage.setItem('sponsorship-cart', JSON.stringify(this.items));
  }

  deserializeCart() {
    let deserializedItems: SponsorshipCartModel[] = <SponsorshipCartModel[]>JSON.parse(localStorage.getItem('sponsorship-cart'));
    if (deserializedItems) {
      this.items = deserializedItems;
    }
  }

  checkCart(conferenceId: any) {
    this.callForSponsorsService.getProductsForConference(conferenceId).subscribe((allProducts) => {
      this.items.forEach((item) => {
        var index = allProducts.findIndex((x) => x.id == item.product.id);
        if (index > -1) {
          var itemToUpdate = allProducts[index];
          if (itemToUpdate.remainingQuantity < item.qty) {
            item.errorMessage = 'This item is no longer availible at this quantity';
          } else {
            item.errorMessage = '';
          }
        } else {
          item.errorMessage = '';
        }
      });
    });
  }
}
