<!--component html goes here -->
<!--<page-load-header *ngIf="!initialized"></page-load-header>-->

<!--<div *ngIf="initialized" class="container-fluid bg-light" style="min-height: 800px;">-->
<!--  <div class="responsive-tabs">-->
<!--    <ul class="nav nav-tabs mb-3 companyNav">-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['events']"><i class="fas fa-calendar-alt"></i> Events</a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['courses']"><i class="fad fa-film"></i> On-Demand</a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['users']"><i class="fas fa-users"></i> Users</a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['organizations']"><i class="fas fa-building"></i> Organizations</a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['sponsor-organizations']"><i class="fas fa-booth-curtain"></i> Sponsor Orgs</a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['conferences']"><i class="fad fa-handshake"></i> Conferences</a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['billing']"><i class="fas fa-sack-dollar"></i> Billing</a>-->
<!--      </li>-->

<!--    </ul>-->
<!--    <router-outlet></router-outlet>-->
<!--  </div>-->
<!--</div>-->

<page-load-header *ngIf="!initialized"></page-load-header>
<main id="orgAdmin" role="main" *ngIf="initialized && auth.user">
  <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #2e3f50">
    <a class="navbar-brand" [routerLink]="['/']">
      <img style="width: 30px" src="../../../assets/svg/circle-icon.svg"/>
    </a>
    <div class="text-white h6 mb-0 mr-3">Super User</div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item"></li>
        <li class="nav-item fullHeight" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['events']">Events</a>
        </li>
        <li class="nav-item fullHeight" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['courses']">Courses</a>
        </li>
        <li class="nav-item fullHeight" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['users']">Users</a>
        </li>
        <li class="nav-item fullHeight" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['organizations']">Organizations</a>
        </li>
        <li class="nav-item fullHeight" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['sponsor-organizations']">Sponsor Organizations</a>
        </li>
        <li class="nav-item fullHeight" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['conferences']">Conferences</a>
        </li>
        <li class="nav-item fullHeight" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['channels']">Channels</a>
        </li>
        <li class="nav-item fullHeight" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['billing']">Billing</a>
        </li>

      </ul>
      <ul class="navbar-nav my-2 my-lg-0">


<!--        <li class="tool-divider d-none d-lg-block"></li>-->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ auth.user.firstName }} {{ auth.user.lastName }}
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <div class="text-muted pl-2 pr-2">
              <img class="avatar u-sm-avatar rounded-circle mr-2" [fallback]="'fetch-avatar?userId=' + auth.user.id" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ auth.user.id }}-avatar.png"/>
              {{ auth.user.firstName }} {{ auth.user.lastName }}
            </div>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" [routerLink]="['/account/profile']">My Account</a>
            <a class="dropdown-item" [routerLink]="['/account/ceus']">CEU History</a>
            <a class="dropdown-item" [routerLink]="['my-events']">My Presentations</a>
            <div *ngIf="auth && auth.isSuperUser()" class="dropdown-divider"></div>
            <a *ngIf="auth && auth.isSuperUser()" class="dropdown-item" [routerLink]="['/super-user']">Super User</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" [routerLink]="[]" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
  <div *ngIf="this.auth && this.auth.impersonated" class="row">
    <div class="col">
      <div class="alert alert-danger mb-0" style="border-radius: 0" role="alert">
        <div class="container-fluid">
          <div class="row h-100">
            <div class="col-md-9 my-auto">
              <h6 class="mb-0">You are currently impersonating this user. Click here to revert.</h6>
            </div>
            <div class="col-md-3 text-right">
              <button type="button" [routerLink]="[]" (click)="auth.unimpersonateUser()" class="btn btn-secondary btn-sm">Revert</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-light space-1" style="min-height: 100vh" [ngClass]="{'container-fluid':padMargins}">
    <router-outlet></router-outlet>
  </div>
</main>

