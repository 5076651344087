<header id="header" class="u-header">
  <div class="u-header__section u-header--white-nav-links">
    <!-- Topbar -->
    <div class="u-header__hide-content pt-2" [ngClass]="{'container-fluid': fullWidthHeader, 'full-width-header': fullWidthHeader, 'container':!fullWidthHeader}">
      <div class="d-flex align-items-center">
        <!-- Language -->

        <div class="ml-auto">
          <!-- Links -->
          <div class="d-d-inline-block ml-sm-auto">
            <ul class="list-inline mb-0">
              <li class="list-inline-item mr-3">
                <div class="dropdown">
                  <a class="dropdown-toggle u-header__navbar-link" style="line-height: 1rem; color: rgba(255, 255, 255, 0.6)" data-toggle="dropdown" href="javascript:void(0)">{{ 'language' | translate }}</a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" style="line-height: 1.5rem" (click)="changeLanguage('en')" href="javascript:void(0)">English</a>
                    <a class="dropdown-item" style="line-height: 1.5rem" (click)="changeLanguage('es')" href="javascript:void(0)">Español</a>
                  </div>
                </div>
              </li>
              <!-- <li class="list-inline-item mr-3">
                <a class="u-header__navbar-link" [routerLink]="['/products-services']">{{ 'Products-Services' | translate }} </a>
              </li> -->
              <li class="list-inline-item mr-0">
                <a class="u-header__navbar-link" target="_blank" href="https://support.behaviorlive.com/hc/en-us/articles/360052524233">{{ 'Help' | translate }} </a>
              </li>
            </ul>
          </div>
          <!-- End Links -->
        </div>

        <ul class="list-inline ml-2 mb-0">
          <!-- Search -->

          <!-- End Search -->

          <!-- Shopping Cart -->
          <!-- <li class="list-inline-item position-relative">
            <a id="shoppingCartDropdownInvoker" class="btn btn-xs btn-icon btn-text-secondary" [routerLink]="['/shop/cart']" role="button" aria-controls="shoppingCartDropdown" aria-haspopup="true" aria-expanded="false" data-unfold-event="hover" data-unfold-target="#shoppingCartDropdown" data-unfold-type="css-animation" data-unfold-duration="300" data-unfold-delay="300" data-unfold-hide-on-scroll="true" data-unfold-animation-in="slideInUp" data-unfold-animation-out="fadeOut">
              <span class="fas fa-shopping-cart btn-icon__inner"></span>
              <span *ngIf="" class="badge badge-sm badge-success badge-pos rounded-circle">2</span>
            </a>

            <div id="shoppingCartDropdown" class="dropdown-menu dropdown-unfold dropdown-menu-right text-center p-7" aria-labelledby="shoppingCartDropdownInvoker" style="min-width: 250px;">
              <span class="btn btn-icon btn-soft-primary rounded-circle mb-3">
                <span class="fas fa-shopping-basket btn-icon__inner"></span>
              </span>
              <span class="d-block">Your Cart is Empty</span>
            </div>
          </li> -->
          <!-- End Shopping Cart -->

          <!-- Account Login -->
          <li *ngIf="this.auth.isLoggedIn() && this.auth.user" class="list-inline-item">
            <!-- Account Sidebar Toggle Button -->
            <a id="sidebarNavToggler" class="btn btn-xs btn-text-secondary cursor-pointer u-sidebar--account__toggle-bg ml-1" (click)="navService.sideBarVisible = true" role="button">
              <span class="position-relative">
                <span class="u-sidebar--account__toggle-text">{{ auth.user.firstName }} {{ auth.user.lastName }}</span>
                <i class="text-primary fas fa-user-circle"></i>
                <!-- <img class="u-sidebar--account__toggle-img" src="../../assets/img/100x100/img1.jpg"  /> -->
              </span>
            </a>
            <!-- End Account Sidebar Toggle Button -->
          </li>
          <li *ngIf="!this.auth.isLoggedIn()" class="list-inline-item mr-0">
            <a class="u-header__navbar-link" [routerLink]="" (click)="loginClicked()">{{ 'login-sign-up' | translate }}</a>
          </li>
          <!-- End Account Login -->
        </ul>
      </div>
    </div>
    <!-- End Topbar -->

    <div id="logoAndNav" [ngClass]="{'container-fluid': fullWidthHeader, 'full-width-header': fullWidthHeader, 'container':!fullWidthHeader}">
      <!-- Nav -->
      <nav class="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space hs-menu-initialized hs-menu-horizontal">
        <!-- Logo -->
        <a class="navbar-brand u-header__navbar-brand u-header__navbar-brand-center" [routerLink]="['/']" aria-label="Front">
          <img class="img-fluid" src="{{settings.brand.logoPathDarkBackground}}"/>
          <!-- <img  class="img-fluid" src="../../assets/svg/main-logo-white.svg" /> -->
        </a>
        <!-- End Logo -->

        <!-- Responsive Toggle Button -->
        <button #hamburger type="button" class="navbar-toggler btn u-hamburger" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
          <span id="hamburgerTrigger" class="u-hamburger__box">
            <span class="u-hamburger__inner"></span>
          </span>
        </button>
        <!-- End Responsive Toggle Button -->

        <!-- Navigation -->
        <div id="navBar" #navBar class="collapse navbar-collapse u-header__navbar-collapse">
          <ul class="navbar-nav u-header__navbar-nav">
            <li class="nav-item u-header__nav-item">
              <a class="nav-link u-header__nav-link" [routerLink]="['/events']">{{ 'Live Events' | translate }}</a>
            </li>


            <!-- Resources -->
            <li class="nav-item hs-has-mega-menu u-header__nav-item" data-event="hover" data-animation-in="slideInUp" data-animation-out="fadeOut" data-max-width="760px" data-position="right">
              <a id="resourcesMegaMenu" class="nav-link u-header__nav-link u-header__nav-link-toggle" href="javascript:;" aria-haspopup="true" aria-expanded="false">
                <span>{{ 'On-Demand' | translate }}</span>
              </a>

              <!-- Resources - Submenu -->
              <div class="hs-mega-menu u-header__sub-menu" aria-labelledby="resourcesMegaMenu">
                <div class="row no-gutters">
                  <div class="col-md-6">
                    <!-- Promo Item -->
                    <div class="u-header__promo-item">
                      <a class="u-header__promo-link" [routerLink]="['/course-library']">
                        <div class="media">
                          <div class="u-header__promo-icon" [inlineSVG]="'../../assets/svg/icons/icon-40.svg'"></div>
                          <div class="media-body">
                            <span class="u-header__promo-title mb-1">{{ 'all-on-demand-content' | translate }}</span>
                            <small class="text-dark">{{ 'view-our-extensive-ceu-libary' | translate }}</small>
                          </div>
                        </div>
                      </a>
                    </div>
                    <!-- End Promo Item -->
                    <!-- Promo Item -->
                    <div class="u-header__promo-item">
                      <a class="u-header__promo-link" [routerLink]="['/course-library']">
                        <div class="media">
                          <div class="u-header__promo-icon" [inlineSVG]="'../../assets/svg/icons/icon-21.svg'"></div>
                          <div class="media-body">
                            <span class="u-header__promo-title mb-1">{{ 'Featured Content' | translate }}</span>
                            <small class="text-dark">{{ 'featured-on-demand-content-hand-selected-by-our-staff' | translate }}</small>
                          </div>
                        </div>
                      </a>
                    </div>
                    <!-- End Promo Item -->
                    <!-- Promo Item -->
                    <div class="u-header__promo-item">
                      <a class="u-header__promo-link" [routerLink]="['/wiba/on-demand']">
                        <div class="media">
                          <img class="u-header__promo-icon" src="../../assets/wiba.png"/>

                          <div class="media-body">
                            <span class="u-header__promo-title mb-1">2023 WIBA Conference</span>
                            <small class="text-dark">View select talks from the 2023 WIBA conference.</small>
                          </div>
                        </div>
                      </a>
                    </div>
                    <!-- End Promo Item -->
                  </div>

                  <!-- Promo -->
                  <div class="col-md-6 u-header__promo">
                    <div class="u-header__promo-inner">
                      <div class="row">
                        <div class="col-sm-6">
                          <!-- Sub Nav Group -->
                          <span class="u-header__sub-menu-title">{{ 'Categories' | translate }}</span>
                          <ul class="u-header__sub-menu-nav-group mb-3">
                            <li *ngFor="let tag of tags">
                              <a *ngIf="tag.showInNav" class="nav-link u-header__sub-menu-nav-link" [routerLink]="['/course-library', tag.name]">{{ tag.name }}</a>
                            </li>
                          </ul>
                          <!-- End Sub Nav Group -->
                        </div>

                        <div class="col-sm-6">
                          <!-- Sub Nav Group -->
                          <span class="u-header__sub-menu-title">{{ 'CEU Types' | translate }}</span>
                          <ul class="u-header__sub-menu-nav-group">
                            <li>
                              <a class="nav-link u-header__sub-menu-nav-link" [routerLink]="['/course-library']" [queryParams]="{ f: 'supervision' }">{{ 'Supervision' | translate }}</a>
                            </li>
                            <li>
                              <a class="nav-link u-header__sub-menu-nav-link" [routerLink]="['/course-library']" [queryParams]="{ f: 'ethics' }">{{ 'Ethics' | translate }}</a>
                            </li>
                            <li>
                              <a class="nav-link u-header__sub-menu-nav-link" [routerLink]="['/course-library']" [queryParams]="{ f: 'standard' }">{{ 'Learning' | translate }}</a>
                            </li>
                          </ul>
                          <!-- End Sub Nav Group -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Promo -->
                </div>
              </div>
              <!-- End Resources - Submenu -->
            </li>

            <!-- Docs -->
<!--            <li class="nav-item u-header__nav-item">-->
<!--              <a class="nav-link u-header__nav-link" [routerLink]="['/conferences']">Jobs</a>-->
<!--            </li>-->
            <li class="nav-item u-header__nav-item">
              <a class="nav-link u-header__nav-link" [routerLink]="['/jobs']">Jobs</a>
            </li>
            <li class="nav-item u-header__nav-item">
              <a class="nav-link u-header__nav-link" [routerLink]="['/conferences']">{{ 'Conferences' | translate }}</a>
            </li>



            <!-- Button -->
            <li class="nav-item u-header__nav-last-item">
              <a *ngIf="!auth.isLoggedIn()" class="btn btn-sm btn-primary transition-3d-hover" [routerLink]="[]" role="button" (click)="loginClicked()"> {{ 'Sign Up' | translate }} </a>
              <a *ngIf="auth.isLoggedIn()" class="btn btn-sm btn-primary transition-3d-hover" role="button" [routerLink]="['/account']"> {{ 'My Account' | translate }} </a>
            </li>
            <!-- End Button -->
          </ul>
        </div>
        <!-- End Navigation -->
      </nav>
      <!-- End Nav -->
    </div>
  </div>
</header>

<div *ngIf="this.auth && this.auth.isLoggedIn() && this.auth.user && !this.auth.isEmailVerified() && !isConfirmEmailRoute" class="row">
  <div class="col">
    <div class="alert alert-danger mb-0" style="border-radius: 0" role="alert">
      <div class="container">
        <div class="row h-100">
          <div class="col-md-9 my-auto">
            <h6 class="mb-0">{{ 'EmailNotVerified' | translate }}</h6>
          </div>
          <div class="col-md-3 text-right">
            <button type="button" [disabled]="verificationEmailSent" [routerLink]="[]" (click)="resendEmailVerification()" class="btn btn-secondary btn-sm">{{ 'Resend Email' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="this.auth && this.auth.impersonated" class="row">
  <div class="col">
    <div class="alert alert-danger mb-0" style="border-radius: 0" role="alert">
      <div class="container">
        <div class="row h-100">
          <div class="col-md-9 my-auto">
            <h6 class="mb-0">You are currently impersonating this user. Click here to revert.</h6>
          </div>
          <div class="col-md-3 text-right">
            <button type="button" [routerLink]="[]" (click)="auth.unimpersonateUser()" class="btn btn-secondary btn-sm">Revert</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ========== END HEADER ========== -->
<!-- ========== SECONDARY CONTENTS ========== -->

<!-- Account Sidebar Navigation -->
<aside *ngIf="auth.user" (clickOutside)="navService.sideBarVisible = false" [exclude]="'#sidebarNavToggler'" [excludeBeforeClick]="true" id="sidebarContent" class="u-sidebar" [ngClass]="{ 'd-none': !navService.sideBarVisible }">
  <div class="u-sidebar__scroller">
    <div class="u-sidebar__container">
      <div class="u-sidebar--account__footer-offset">
        <!-- Toggle Button -->
        <div class="d-flex justify-content-between align-items-center pt-4 px-7">
          <h3 class="h6 mb-0">{{ 'My Account' | translate }}</h3>

          <button type="button" (click)="navService.sideBarVisible = false" class="close ml-auto">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- End Toggle Button -->

        <!-- Content -->
        <div class="js-scrollbar u-sidebar__body">
          <!-- Holder Info -->
          <header class="d-flex align-items-center u-sidebar--account__holder mt-3">
            <div class="position-relative">
              <!-- <img *ngIf="auth.user.avatar" class="u-sidebar--account__holder-img" src="/fetchimage?filename={{ auth.user.avatar }}&width=100"  /> -->
              <img *ngIf="!auth.user.avatar" class="u-sidebar--account__holder-img" src="../../assets/img/avatar-placeholder.png"/>
              <span class="badge badge-xs badge-outline-success badge-pos rounded-circle"></span>
            </div>
            <div class="ml-3">
              <span class="font-weight-semi-bold">{{ auth.user.firstName }} {{ auth.user.lastName }} </span>
            </div>

            <!-- Settings -->
            <div class="btn-group position-relative ml-auto mb-auto">
              <a id="sidebar-account-settings-invoker" class="btn btn-xs btn-icon btn-text-secondary rounded" href="javascript:;" role="button" aria-controls="sidebar-account-settings" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown" data-unfold-event="click" data-unfold-target="#sidebar-account-settings" data-unfold-type="css-animation" data-unfold-duration="300" data-unfold-delay="300" data-unfold-animation-in="slideInUp" data-unfold-animation-out="fadeOut">
                <span class="fas fa-ellipsis-v btn-icon__inner"></span>
              </a>

              <div id="sidebar-account-settings" class="dropdown-menu dropdown-unfold dropdown-menu-right" aria-labelledby="sidebar-account-settings-invoker">
                <a class="dropdown-item" [routerLink]="" (click)="logout()"> {{ 'Sign Out' | translate }}</a>
              </div>
            </div>
            <!-- End Settings -->
          </header>
          <!-- End Holder Info -->

          <div class="u-sidebar__content--account">
            <!-- List Links -->
            <ul class="list-unstyled u-sidebar--account__list">
              <li class="u-sidebar--account__list-item">
                <a class="u-sidebar--account__list-link" [routerLink]="['/account/profile']">
                  <span class="fas fa-key u-sidebar--account__list-icon mr-2"></span>
                  {{ 'My Account' | translate }}
                </a>
              </li>
              <li class="u-sidebar--account__list-item">
                <a class="u-sidebar--account__list-link" [routerLink]="['/account/live-events']">
                  <span class="fas fa-users-class u-sidebar--account__list-icon mr-2"></span>
                  {{ 'My Live Presentations' | translate }}
                </a>
              </li>
              <li class="u-sidebar--account__list-item">
                <a class="u-sidebar--account__list-link" [routerLink]="['/account/library']">
                  <span class="fas fa-books u-sidebar--account__list-icon mr-2"></span>
                  {{ 'Purchased Courses' | translate }}
                </a>
              </li>
              <li class="u-sidebar--account__list-item">
                <a class="u-sidebar--account__list-link" [routerLink]="['/account/events']">
                  <span class="fas fa-calendar-alt u-sidebar--account__list-icon mr-2"></span>
                  {{ 'Registered Events' | translate }}
                </a>
              </li>
              <li class="u-sidebar--account__list-item">
                <a class="u-sidebar--account__list-link" [routerLink]="['/account/ceus']">
                  <span class="fad fa-certificate u-sidebar--account__list-icon mr-2"></span>
                  {{ 'CEU History' | translate }}
                </a>
              </li>

              <li *ngIf="auth.isSuperUser()" class="u-sidebar--account__list-item">
                <a class="u-sidebar--account__list-link" [routerLink]="['/super-user']">
                  <span class="fas fa-tools u-sidebar--account__list-icon mr-2"></span>
                  Super User
                </a>
              </li>
            </ul>

            <div *ngIf="(auth.user.organizations && auth.user.organizations.length > 0) || (auth.user.sponsorOrganizations && auth.user.sponsorOrganizations.length > 0)" class="u-sidebar--account__list-divider"></div>
            <div *ngIf="(auth.user.organizations && auth.user.organizations.length > 0) || (auth.user.sponsorOrganizations && auth.user.sponsorOrganizations.length > 0)" class="ml-3">
              <span class="font-weight-semi-bold">{{ 'My Organizations' | translate }}</span>
            </div>
            <ul class="list-unstyled u-sidebar--account__list">
              <li *ngFor="let company of auth.user.organizations" class="u-sidebar--account__list-item">
                <a *ngIf="OrganizationRole[company.organizationRole]?.toString() == OrganizationRole.Admin.toString()" class="u-sidebar--account__list-link" [routerLink]="['/organizations', company.slug, 'admin']">
                  <span class="fas fa-building u-sidebar--account__list-icon mr-2"></span>
                  {{ company.name }}
                </a>
                <a *ngIf="OrganizationRole[company.organizationRole]?.toString() == OrganizationRole.User.toString()" class="u-sidebar--account__list-link" [routerLink]="['/organizations', company.slug]">
                  <span class="fas fa-building u-sidebar--account__list-icon mr-2"></span>
                  {{ company.name }}
                </a>
              </li>
              <li *ngFor="let company of auth.user.sponsorOrganizations" class="u-sidebar--account__list-item">
                <a class="u-sidebar--account__list-link" [routerLink]="['/sponsorship-portal', company.id, 'conferences']">
                  <span class="fas fa-building u-sidebar--account__list-icon mr-2"></span>
                  {{ company.name }}
                </a>
              </li>
            </ul>

            <!-- End List Links -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer -->

  <!-- End Footer -->
</aside>
